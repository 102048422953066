export default (props) => {
  const lang = inject('lang');

  return {
    styles: {
      cssVariables: computed(() => Object.keys(useTokenColors(props.data?.primaryColor).tokenColors.value).map((token) => `--${token}: ${useTokenColors(props.data?.primaryColor).tokenColors.value[token]?.[props.data?.appearance]};`)),
      spacerTop: computed(() => {
        const spacer = {
          small: 'pt-4 md:pt-6',
          medium: 'pt-8 md:pt-12',
          large: 'pt-14 md:pt-20',
        };
        return spacer[props.data?.spacerTop] || '';
      }),
      spacerBottom: computed(() => {
        const spacer = {
          small: 'pb-4 md:pb-6',
          medium: 'pb-8 md:pb-12',
          large: 'pb-14 md:pb-20',
        };
        return spacer[props.data?.spacerBottom] || '';
      }),
      textAlignment: computed(() => {
        const textAlignment = {
          left: '!text-left justify-start',
          center: '!text-center justify-center',
          right: '!text-left md:!text-right justify-start md:justify-end',
        };
        return textAlignment[props.data?.text?.alignment || props.data?.alignment || props.data?.itemAlignment] || '';
      }),
      containerAlignment: computed(() => {
        const containerAlignment = {
          targetLeft: 'flex-col md:flex-row',
          targetRight: 'flex-col-reverse md:flex-row-reverse',
        };
        return containerAlignment[props.data?.arrangement] || '';
      }),
      imageSize: computed(() => {
        const imageSize = {
          small: 'w-1/4',
          medium: 'w-1/2',
          large: 'w-full',
        };
        return imageSize[props.data?.image?.size || props.data?.size || props.data?.itemImage?.size] || '';
      }),
      imageCrop: computed(() => {
        const imageCrop = {
          none: '',
          circle: 'rounded-full aspect-square',
          square: 'aspect-square',
          '4/3': 'aspect-[4/3]',
          '16/9': 'aspect-[16/9]',
          '3/4': 'aspect-[3/4]',
          original: '[&>img]:object-fill',
        };
        return imageCrop[props.data?.image?.crop || props.data?.crop || props.data?.itemImage?.crop] || '';
      }),
      cornerRadius: computed(() => {
        const cornerRadius = {
          true: 'rounded-2xl',
          false: '',
        };

        if (props.data?.hasRoundedCorners || props.data?.image?.hasRoundedCorners || props.data?.itemImage?.hasRoundedCorners) {
          return cornerRadius.true;
        }

        return cornerRadius.false;
      }),
      boxedLogo: computed(() => {
        const boxedLogo = {
          true: 'rounded py-2 px-3.5 bg-white',
          false: '',
        };
        return boxedLogo[props.data?.isLogoBoxed] || '';
      }),
      boxed: computed(() => {
        const boxed = {
          box: 'border border-(border-muted) rounded-lg p-4 pb-0',
          none: '',
        };
        return boxed[props.data?.itemContainer] || '';
      }),
      gridColumns: computed(() => {
        const gridColumns = {
          1: 'grid-cols-1',
          2: 'grid-cols-1 sm:grid-cols-2',
          3: 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3',
          4: 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4',
          5: 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5',
        };
        return gridColumns[props.data?.columnCount] || '';
      }),
      iconPlacement: computed(() => {
        const iconPlacement = {
          top: 'flex-col',
          left: 'flex-row',
        };
        return iconPlacement[props.data?.itemIconPlacement] || '';
      }),
    },
    lang: lang || '',
    data: computed(() => props.data),
    ctaButton: computed(() => {
      if (!props.data?.ctaButton?.targetSection) {
        return {
          ...props.data?.ctaButton,
          targetSection: useTemplatesStore().template?.sections?.find((section) => section?.type === 'formBasic')?.id || '',
        };
      }

      return props.data?.ctaButton;
    }),
    $t: (key, options) => useT(key, options, { locale: (lang?.value || '_cs').replace('_', '') }),
  };
};
